import React, {useState} from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import {
  Section,
  SectionTitle,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import LikeProductCarousel from "../components/LikeProductCarousel"
import QuotePopup from "../components/QuotePopup"
import CallNow from "../components/CallNow"
import BreakpointUp from "../components/Media/BreakpointUp"
import PrimaryForm from "../components/PrimaryForm"
import { StaticImage } from "gatsby-plugin-image"
import ListArrow from "../images/BulletIcon.svg"
import MapStyle from "../components/MapStyle/Cities/BuildingMap"
import LocationStyle from "../components/LocationStyle"

const SectionOverlayBg = styled.div`
  height: 100%;
  width: 30%;
  position: absolute;
  top: 0px;
  right: 0;
  &:after {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 0;
    width: 90%;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(242, 244, 249, 1) 0%,
      rgba(242, 244, 249, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`
const SectionOverlay = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SectionOverlayContent = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 855px;
  & p {
    margin-bottom: 50px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 38px;
    }
    & strong {
      color: #000;
      font-weight: 700;
      line-height: 24px;
    }
  }
  & small {
    display: block;
    color: #0b619b;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }

  & ul {
    list-style: none;
    margin: 0 -5px;
    padding: 0;
    & li {
      display: inline-block;
      padding: 0 5px;
      & a {
        color: #000;
        font-weight: 700;
        text-decoration: underline;
        &:hover {
          color: #0b619b;
          text-decoration: none;
        }
      }
    }
  }
`
const LeftContent = styled.div`
  & h1{
    font-size: 38px;
    line-height: 48px;
    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 58px;
    }
    @media (min-width: 992px) {
      font-size: 62px;
      line-height: 72px;
    }
  }
  & p {
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 40px;
    }
    > a{
      &:hover{
        text-decoration:underline;
      }
    }
  }
`

const SectionEditor = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.lg`        
        margin-right: -30px;
        margin-left: -30px; 
    `}
  ul {
    font-weight: 700;
    li {
      padding-left: 40px;
      vertical-align: middle;
      margin: 0;
      line-height: 26px;
      position: relative;
      + li {
        margin-top: 15px;
      }
      &:before {
        content: url(${ListArrow});
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-shadow: 0 0 0px 6px rgba(23, 27, 39, 0.04);
        vertical-align: middle;
        background: #fff;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 3px;
      }
    }
  }
  &.section-editor {
    + .section-editor {
      border-top: 1px solid #dde4f0;
      padding-top: 70px;
      margin-top: 70px;
    }
  }
`
const SectionEditorLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`        
        padding-right: 30px;
        padding-left: 30px; 
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    ${BreakpointUp.lg`
            font-size:20px;
            line-height:38px;
        `}
  }
`
const SectionEditorRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`        
        padding-right: 30px;
        padding-left: 30px; 
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    & small {
      font-size: 20px;
      line-height: 38px;
      display: block;
      font-weight: 700;
      color: #999;
    }
  }
  & strong {
    display: block;
    font-weight: 700;
    color: #000;
    line-height: 28px;
  }
`

const City = ({ data, location, pageContext: { category, city } }) => {
  const [isVisibleQuote, setQuoteVisible] = useState(false);

  const pageData = data.contentfulState
  const cityPageData = city       
  const productCategory = category
  const relatedProductsData = []
    
  data.allContentfulProduct.edges.map((product) => {
     if (product.node.productCategory.name.toLowerCase() === productCategory.split("-").join(" ")) {
       relatedProductsData.push(product.node)
     } 
  })
  
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

  return (
    <Layout location={location}>
      <Seo
        title={`Metal Buildings ${city} ${pageData.abbreviation} | Prefab Steel Building Kits for Sale ${city} ${pageData.name}`}
        description={`Metal buildings ${city} ${pageData.abbreviation} - Explore our vast range of prefab metal buildings and kits for sale in ${city} ${pageData.name}. We offer the best quality steel buildings at the lowest prices in ${city} ${pageData.abbreviation}.`}
      />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <Link
            to={`/${category}-${pageData.name
              .split(" ")
              .join("-")
              .toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
          >
            {" "}
            {pageData.name} /{" "}
          </Link>
          <span>{city}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="90px"
        xpt="120px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left top-0"
      >
        <div className="container">
          <MainPanel alignItems="center">
            <LeftPannel>
              <LeftContent>
                <h1>{`Metal Buildings in ${city}`}</h1>
                <p>
                  You might be looking for a residential, agricultural, or
                  commercial building as a solution to severe weather condition,
                  theft, and storage. The superior quality Metal buildings by
                  Coast To Coast Carports, Inc. are trusted widely across the
                  city of {city}. Our Metal Buildings come in all different
                  sizes, and with customizations, you can own a structure unique
                  to your taste!
                </p>
                <p>
                Coast to Coast Carports offers a wide selection of top-quality metal buildings that include barns, RV covers, garages, large metal buildings, carports, commercial metal buildings, and more. These buildings are perfect for all your residential, commercial, agricultural, and residential needs. Our metal buildings in {city} are designed and engineered to withstand harsh weather conditions such as heavy rain, snowfall, lightning, termites, high winds, and even fire, thanks to their non-combustible nature. These custom buildings are highly versatile and are perfect for storage, parking, entertainment, office, retail store, and more, making them an increasingly popular choice for all your needs. To find the ideal structure that suits your requirements, get in touch with our building experts at <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a> they will help you get the building your specific requirement. Buy now!
                </p>
              </LeftContent>
            </LeftPannel> 
            <RightPannel>
              <div className="isSticky">
                <PrimaryForm states={data.allContentfulState.edges} location = {location} />
              </div>
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      <Section pt="0" pb="90px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <MapStyle data = {pageData} cityData={cityPageData}  />
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="30px">Explore {city}’s Metal Buildings</SectionTitle>
        </div>
        <LikeProductCarousel 
        relatedProductsData={relatedProductsData} 
        openQuoteModal={() => setQuoteVisible(true)} 
        />
      </Section>
      {(pageData.abbreviation === "ID" || pageData.abbreviation === "TX" || pageData.abbreviation === "TN" || pageData.abbreviation === "OR" ) && (city === "Heyburn" || city === "Amarillo" || city === "Smithville" || city === "Stanfield") &&  
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#DDE4F0">
          <div className="container">
            <LocationStyle city={cityPageData}  />
          </div>
        </Section>
      }

      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionEditor className="section-editor">
            <SectionEditorLeft>
              <h2>
                Metal Buildings in {city} {pageData.abbreviation}
              </h2>
              <p>
                If you’re in the market for a dependable, secure, yet affordable
                means of shelter or storage in {city}, you’ve come to the right
                place. Here at Coast to Coast Carports, we offer a variety of
                metal buildings for only a fraction of the price of traditional,
                wooden structures. And when you purchase a steel building from
                us, you can have peace of mind knowing your property will be
                protected from anything Mother Nature may throw its way. To give
                you a better idea on what products we offer, we’ve provided a
                list below with several of our building styles. You can also
                check our gallery out to see a few ways our structures have been
                used.
              </p>
            </SectionEditorLeft>
            <SectionEditorRight>
              <ul>
                <li>Industrial Buildings</li>
                <li>Commercial Buildings</li>
                <li>Metal Garages</li>
                <li>Metal Workshops</li>
                <li>Metal Carports</li>
                <li>Agricultural Equipment Storage Buildings</li>
                <li>Gambrel Barns</li>
                <li>Clear-Span Steel Structures</li>
                <li>Outdoor Storage Sheds</li>
                <li>RV Covers</li>
              </ul>
            </SectionEditorRight>
          </SectionEditor>
          <SectionEditor className="section-editor">
            <SectionEditorLeft>
              <h2>Customize Your {city} Metal Building</h2>
              <p>
                Without seeing the final product prior to purchase, it may be
                difficult for customers to go through with their order. But with
                the Color Planner and 3D Building Designer offered at Coast to
                Coast Carports, customizing your {city} steel building has never
                been easier! From the color of your room, to the doors and
                windows, you have full control over the aesthetics of your
                structure. Oh, and did we mention you can do all this from the
                comfort of your home? That’s right! Sit back on the couch and
                watch your dream structure come to life.
              </p>
            </SectionEditorLeft>
            <SectionEditorRight>
              <p>
                <small>
                  A few of our customizations include, but are not limited to,
                  the following options:
                </small>
              </p>
              <ul>
                <li>
                  <h3>Roof Style</h3> Regular, A-frame, and Vertical
                </li>
                <li>
                  <h3>Colors</h3> Choose your ideal color combination for the
                  trim, roof, and walls of your building
                </li>
                <li>
                  <h3>Doors</h3> We offer garage doors, walk-in doors, and
                  frame-outs
                </li>
                <li>
                  <h3>Windows</h3> Choose your preferred window size or a window
                  frame-out
                </li>
                <li>
                  <h3>Gauge of frame</h3> We offer 12- and 14- gauge tubing
                </li>
              </ul>
            </SectionEditorRight>
          </SectionEditor>
        </div>
      </Section>
      <Section
        pt="90px"
        pb="90px"
        xpt="60px"
        xpb="60px"
        bgColor="#fff"
        className="circle-right"
      >
        <div className="container mw-1120">
          <MarkdownContent>
            <h2>Metal Buildings {city} Prices</h2>
            <p>
              We understand how important it is for the price of your {city}{" "}
              metal building to be affordable, it’s the breaking point of a
              deal. However, if you are limited to a budget, there are a few
              things to keep in mind while going through the buying process. The
              final cost of your structure will be affected by its size and
              style, your location, added customizations, and the manufacturer
              you obtain the building from. But keep in mind, we also offer
              excellent financing and rent-to-own programs for all our customers
              to take advantage of. Apply online and be pre-approved within a
              matter of minutes!
            </p>
            <h2>Benefits of Steel Buildings in {city}</h2>
            <p>
              Metal buildings offer people in the {city} area a convenient way
              to store their belongings, park their vehicles, and fulfill every
              other need they may have. But there are several benefits to
              investing in one of our metal buildings at Coast to Coast
              Carports. Our steel structures offer:
            </p>
            <ul>
              <li>Ultimate Security</li>
              <li>Energy-Efficient Components</li>
              <li>Quick and Easy Assembly</li>
              <li>Variety of Customizations</li>
              <li>Affordable Prices</li>
              <li>Years of Use</li>
            </ul>
            <p>
              If you’d like to know more about what our products have to offer
              you at Coast to Coast Carports, just give us a call today.
            </p>
            <h2>
              Delivery and Installation in {city}, {pageData.abbreviation}
            </h2>
            <p>
              Before your metal building can be assembled, there are a few
              things that you (the customer) must complete. First, you must have
              the site cleared of all debris and vegetation, as well as having
              it leveled. Second, the difference between your site and the
              delivery truck cannot be more than 50 feet or there will be
              additional labor fees charged. Once you have completed all the
              site preparations and obtained the required permits, the
              installation process can begin. And to say thank you for your
              service, we include all delivery and installation services in your
              final price.
            </p>
          </MarkdownContent>
        </div>
      </Section>
      <Section
        pt="140px"
        pb="140px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left"
      >
        <SectionOverlayBg>
          <StaticImage src="../images/purchase-bg.jpg" alt="purchase" />
        </SectionOverlayBg>
        <div className="container">
          <SectionOverlay>
            <SectionOverlayContent>
              <h2>Contact Coast to Coast for ALL Your {city} Building Needs</h2>
              <p>
                Coast to Coast Carports has been providing quality products,
                exceptional prices, and excellent customer service to the {city}{" "}
                area since 2001. We work alongside each customer to ensure they
                find their dream structure for a price they can afford. So,
                don’t wait any longer, give us a call today at{" "}
                <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a> to get started.
                We can’t wait to help you!
              </p>
              <small>ALSO Explore :</small>
              <ul>
                <li>
                  <Link
                    to={`/metal-carports-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal Carports {city} {pageData.abbreviation}
                  </Link>
                  ,
                </li>
                <li>
                  <Link
                    to={`/metal-garages-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal Garages {city} {pageData.abbreviation}
                  </Link>
                  ,
                </li>
                <li>
                  <Link
                    to={`/metal-rv-covers-${city.replace(/[^a-zA-Z0-9 ]/g, '').split(" ").join("-").toLowerCase()}-${pageData.abbreviation.toLowerCase()}`}
                  >
                    Metal RV Covers {city} {pageData.abbreviation}
                  </Link>
                </li>
              </ul>
            </SectionOverlayContent>
          </SectionOverlay>
        </div>
      </Section>
      <CallNow />
      <QuotePopup
        isVisible={isVisibleQuote}
        location={location}
        onClose={() => setQuoteVisible(false)}
      />
    </Layout>
  )
}

export default City

export const pageQuery = graphql`
  query MetalBuildingsCityLandingQuery(
    $id: String!
    $productCategory: String!
  ) {
    contentfulState(id: { eq: $id }) {
      name
      abbreviation
      locationForCities {
        cityName
        metalBuildingAddress
        metalBuildingContactNumber
        metalBuildingLocationLink
        metalBuildingMapEmbedLink {
          metalBuildingMapEmbedLink
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          locationForCities {
            cityName
            metalBuildingAddress
            metalBuildingContactNumber
            metalBuildingLocationLink
            metalBuildingMapEmbedLink {
              metalBuildingMapEmbedLink
            }
          }
        }
      }
    }
    allContentfulProduct(
      filter: { productCategory: { name: { eq: $productCategory } } }
    ) {
      edges {
        node {
          id
          productName
          productSku
          productName
          url
          productImages {
            title
            gatsbyImageData
          }
          productCategory {
            name
          }
          width
          height
          length
          price
        }
      }
    }
  }
`
